import {policy} from "./policy";
import {one, two, three, four} from "./getAcc"

export const Google = {
    "Как получить аккаунт с купоном": [
        one,
        two,
        three,
        four,
        `<span class="accordionIndexCardRefillPage">5</span><span class="accordionTextRefillPage">Пополните аккаунт на 300$</span>`,
        `<span class="accordionIndexCardRefillPage">6</span><span class="accordionTextRefillPage">Купон на 300$ активируется автоматически при соответствии условиям и отсутствия нарушений политик рекламных систем.</span>`,
    ],
    "Условия активации купона на 300$": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">В течениe 30 дней нужно потратить 300$ и в течениe 10 дней после того как вы потратили начисляется промокод.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Промокод от ps.kz действует лишь на 1 рекламный аккаунт.</span>`,
        `<span class="accordionIndexCardRefillPage">3</span><span class="accordionTextRefillPage">Предложение действует на новый домен, ранее не рекламирующийсяв <b>Google Ads</b>.</span>`,
        `<span class="accordionIndexCardRefillPage">4</span><span class="accordionTextRefillPage">Отслеживать статус промокода можно на странице <a target="_blank" href="https://ads.google.com/nav/selectaccount?authuser=0&dst=/aw/billing/promotions&f.sid=6869030318699676565&pli=1">Промоакции</a>.</span>`,
        `<span class="accordionIndexCardRefillPage">5</span><span class="accordionTextRefillPage">Дополнительные ресурсы: <a target="_blank" href="https://support.google.com/google-ads/answer/6388096?hl=ru">Cправка Google Ads</a>.</span>`,
    ],
    "Условия пополнения аккаунта": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Минимальная сумма пополнения 50 000 ₸ без НДС.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Вознаграждение агентства составляет 5% без НДС</span>`,
        `<span class="accordionIndexCardRefillPage">3</span><span class="accordionTextRefillPage">Пополнение <b>физических лиц</b>  осуществляется в течениe часа,с момента поступления денежных средств на расчетный счет.</span>`,
        `<span class="accordionIndexCardRefillPage">4</span><span class="accordionTextRefillPage">Пополнение <b>юридических лиц</b> осуществляется не ранее 12.00 часов рабочего дня, следующего за днем поступления денежных средств на расчетный счет.</span>`,
    ],
    "Нарушения за которые аккаунт может быть заблокирован": policy
}


    // `<span class="accordionIndexCardRefillPage"></span>
    //     <div>
    //         <span class="accordionTextRefillPage"></span>
    //         <ul>
    //             <li><span class="accordionTextRefillPage"></span></li>
    //             <li><span class="accordionTextRefillPage"></span></li>
    //             <li><span class="accordionTextRefillPage"></span></li>
    //         </ul>
    //     </div>`,