import {policy} from "./policy";
import {five, four, one, three, two} from "./getAcc";

export const TikTok = {
    "Как получить аккаунт с Cashback 5%": [
        one,
        two,
        three,
        four,
        five,
        `<span class="accordionIndexCardRefillPage">6</span><span class="accordionTextRefillPage">Cashback в размере 5% будет предоставлен автоматически на первое пополнение рекламного аккаунта.</span>`
    ],
    "Условия применения Cashback": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Cashback от <b>ps.kz</b> действует лишь на 1 рекламный аккаунт.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Cashback 5% будет представлен единоразово на сумму первого пополнения, а также вознаграждение агентства на первое пополнение равно 0%. На последующие пополнения применяется вознаграждение агентства в размере 5% от суммы пополнения без учета НДС.</span>`
    ],
    "Условия пополнения аккаунта": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Минимальная сумма пополнения 50 000 ₸ без НДС.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Аккаунт пополняется с cashback 5% и вознаграждением агентства 0% от суммы пополнения без НДС предоставляется только на 1 (первое) пополнение.</span>`,
        `<span class="accordionIndexCardRefillPage">3</span><span class="accordionTextRefillPage">Вознаграждение агентства составляет 5% от суммы пополнения без НДС на последующие пополнения.</span>`,
        `<span class="accordionIndexCardRefillPage">4</span><span class="accordionTextRefillPage">Пополнение <b>физических лиц</b> осуществляется в течениe часа,с момента поступления денежных средств на расчетный счет.</span>`,
        `<span class="accordionIndexCardRefillPage">5</span><span class="accordionTextRefillPage">Пополнение <b>юридических лиц</b> осуществляется не ранее 12.00 часов рабочего дня, следующего за днем поступления денежных средств на расчетный счет.</span>`,
    ],
    "Нарушения за которые аккаунт может быть заблокирован": policy
}

    // `<span class="accordionIndexCardRefillPage"></span><span class="accordionTextRefillPage"></span>`
