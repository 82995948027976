import {policy} from "./policy";
import {five, four, one, three, two} from "./getAcc";

export const Meta = {
    "Как получить аккаунт Meta с закрывающими документами": [one,two, three, four, five],
    "Условия создания аккаунта Meta с закрывающими документами": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Промокод от <b>ps.kz</b> действует лишь на 1 рекламный аккаунт.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Аккаунт создается на основе заполненной заявкии позволяет получить закрывающие документы для юридических лиц.</span>`
    ],
    "Условия пополнения аккаунта": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Минимальная сумма пополнения 50 000 ₸ без НДС.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Вознаграждение агентства составляет 5% от суммы пополнения без НДС на последующие пополнения.</span>`,
        `<span class="accordionIndexCardRefillPage">3</span><span class="accordionTextRefillPage">Пополнение <b>физических лиц</b> осуществляется в течениe часа,с момента поступления денежных средств на расчетный счет.</span>`,
        `<span class="accordionIndexCardRefillPage">4</span><span class="accordionTextRefillPage">Пополнение <b>юридических лиц</b> осуществляется не ранее 12.00 часов рабочего дня, следующего за днем поступления денежных средств на расчетный счет.</span>`,
    ],
    "Нарушения за которые аккаунт может быть заблокирован": policy
}

    // `<span class="accordionIndexCardRefillPage"></span><span class="accordionTextRefillPage"></span>`
